import React from "react";
import './homepage.css';
import HomepageHeader from "../Homepage/Header/header";
import HomepageAbout from "./About/about";
import HomepageHome from "./Home/home";
import HomepageFeatures from "./Features/feature";
import HomepageCommitment from "./commitment/commitment";
import HomePageShop from "./Shop/shop";
import HomePageFooter from "./Footer/footer";



const HomePage = () => {
    return (
        <>
        <HomepageHeader />
        <HomepageHome />
        <HomepageAbout />
        <HomepageFeatures /> 
        <HomepageCommitment />
        <HomePageShop />
        <HomePageFooter />
        </>
    )
}

export default HomePage;