import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import mobile from '../../../assets/vendorLogo/join/monilei.png'
import lowPrice from '../../../assets/vendorLogo/join/Lowprice.png'
import profit from '../../../assets/vendorLogo/join/profit .png'
import ModalForm from "../vendorform/form";


const VendorJoinCLub = () => {
    const navigate = useNavigate();
    const [open ,setOpen] = useState(false)

    return (
        <>
        <section id="vendor-join " className="d-flex gap-5 flex-column join-top-section  p-0 " >
            <div className="vendor-container">
                <div className=" join-card-text-cont " >
                <h1> Join the MYTRA Vendor Club  </h1>
                <p> Make maximum out of your business</p>
                <button  onClick={() => setOpen(!open)}  >Start for Free</button>
                </div>
            </div>
        </section>
        <section id="vendor-join2" className="join-bottom-section" >
            <div className="vendor-container">
                <div className="join-card-container">
                    <div className="join-card1">
                        <img src={profit} alt="" />
                        <h3 className="m-0" >Ease of Sell</h3>
                        <p className="m-0" >Sell your products with ease, with all support provided by <span>MYTRA</span> .</p>
                    </div>

                    <div className="join-card2">
                        <img src={lowPrice} alt="" />
                        <h3 className="m-0" >Low cost to Vendor</h3>
                        <p className="m-0" >Sell your goods at a very low cost and <span>maximum profit</span> .</p>
                    </div>
                </div>
               <div className="row align-items-center ">
                <div className="col-lg-6">
                    <div className=" join-card2-text-cont " >
                    <h1>Only a <span>10%</span> fee applies when <span>you</span> make a sale.</h1>
                    <p>Join our vendor community and take advantage of these opportunities to grow your business.</p>

                <button  onClick={() => setOpen(!open)}  >Start for Free</button>
                {
        open && 
          <ModalForm openModal={open} setOpenModal={setOpen} />
     }
                </div>

                </div>
                <div className="col-lg-6 text-end">
                    <img src={mobile} alt="" className="img-fluid mt-3 mt-md-0 " />
                </div>
               </div>
            </div>
        </section>
        </>
    )
}

export default VendorJoinCLub;





