import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import Loader from "./layouts/loader/Loader";
//react toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomePage from "./views/Homepage/homepage.jsx";
import TermsAndConditions from "./views/Homepage/Terms&conditions/terms.jsx";
import ReturnPolicy from "./views/Homepage/ReturnPolicy/return.jsx";
import Policy from "./views/Homepage/policy/Policy.jsx";
import VendorPage from "./views/VendorPage/vendorePage.jsx";




const App = () => {


  return (

<BrowserRouter>
      <Suspense 
      // fallback={<Loader />}
      >
          <Routes>
            {/* <Route path="/home" name="Login Page" element={<HomePage />} /> */}
            <Route path="/terms&conditions" element={<TermsAndConditions />} />
            <Route path="/returnpolicy" element={<ReturnPolicy />} />
            <Route path="/policy" element={<Policy />} />


            <Route path="/" name="Home Page" element={<HomePage />} />
            <Route path="/vendor" name="Vendor Page" element={<VendorPage />} />

            
        

          </Routes>
      <ToastContainer />

      </Suspense>
      </BrowserRouter>

  );
};

export default App;
