import React from "react";
import header_logo from "../../../assets/Homepage_image/logo.svg";
import { Link, useNavigate } from "react-router-dom";


const HomepageHeader = () => {
    const navigate = useNavigate();
    
    return (
        <>
        <header id="header">
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="#">
                        <img src={header_logo} alt="" className="img-fluid" />
                        {/* <sup>TM</sup> */}
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                        <ul className="navbar-nav text-center align-items-lg-center">
                   

                            <li className="nav-item">
                                <a className="nav-link" href="#home-about">About</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="#mytra-features">Features</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="#footer">Contact Us</a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/vendor">Become a Vendor</Link>
                            </li>
{/* 
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="true">Login</a>
                                <div className="dropdown-menu" data-popper-placement="bottom-start">
                                    <a className="dropdown-item" onClick={()=>navigate("/vendor/login")} >Vendor Login</a>
                                    <a className="dropdown-item" onClick={()=>navigate("/club/login")} >Club Login</a>
                                </div>
                            </li> */}

                            {/* <li className="nav-item">
                                <a className="nav-link btn dark-btn mt-0" href="#">Start Your Ride &nbsp;<img src="images/arrow-down.svg" alt="" height="15" className="img-fluid" /></a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
        </>
    )
}

export default HomepageHeader;