import React from "react";
import logo_white from "../../../assets/vendorLogo/footer/footerlogo.png"
import map from "../../../assets/vendorLogo/footer/location.png"
import email from "../../../assets/vendorLogo/footer/email.png"
import mbl from "../../../assets/vendorLogo/footer/call.png"
import google_play from "../../../assets/vendorLogo/footer/playstore.png"
import app_store from "../../../assets/vendorLogo/footer/appstore.png"


import instagram from "../../../assets/vendorLogo/footer/instagram.png"
import facebook from "../../../assets/vendorLogo/footer/facebook.png"
import linkedIn from "../../../assets/vendorLogo/footer/linkedIn.png"
import twitter from "../../../assets/vendorLogo/footer/twitter.png"

import { Link } from "react-router-dom";




const VendorFooter  = () =>{
    return (
        <footer id="Vendor-footer" className="pb-0">
            <div className="container">
               
            
                <div className="row">
                    <div className="col-xl-4 col-md-6 mb-5 mb-lg-0 d-flex justify-content-start ">
                        <div className="vendor-footer-content  ">
                            <div className="" ><img src={logo_white} alt="" className="img-fluid " /><sup className="text-light" >TM</sup></div>
                            <div className="vendor-download-btn-cont" >
                            <p>Download the app by clicking the link below :</p>
                            <ul className="app-download list-inline">
                                <li className="list-inline-item"><a href="https://play.google.com/store/apps/details?id=com.thebikerscompany"><img src={google_play} alt="" className="img-fluid" /></a></li>
                                <li className="list-inline-item"><a href="https://apps.apple.com/in/app/mytra/id6526488238"><img src={app_store} alt="" className="img-fluid" /></a></li>
                            </ul>
                            </div>
                        </div>
                    </div>

          

                    <div className="col-xl-2 col-md-6">
                        <div className="footer-links">
                            <h4 className="mb-md-5 mb-3">Pages</h4>
                            <ul>
                                <li><Link to="/">Home </Link></li>
                                <li><Link to="/">About Us</Link></li>
                                <li><Link to="/returnpolicy">Return & Refund</Link></li>

                                
                                <li> <Link to='/policy' > Privacy Policy</Link></li>
                                <li><Link to="/terms&conditions">Terms & Conditions</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="footer-links">
                            <h4 className="mb-md-5 mb-3">Contact</h4>
                            
                            <p> <img src={mbl} alt="phone" className="img-fluid" /> Call +91 91586 00561</p>
                            <p><img src={email} alt="support" className="img-fluid" /> support@mytra.club</p>
                            <p><img src={map} alt="address" className="img-fluid" /> Pune, Maharashtra - 45</p>

                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6">
                        <div className="footer-links">
                            <h4 className="mb-md-5 mb-3">Social</h4>
                            <ul className="social-icons  ">
                             
                                <li  >  <Link to={`https://www.facebook.com/share/u7NtcbuCzfFhrGZF/?mibextid=LQQJ4d`} target="_blank" >
                                <img src={facebook} alt="" /></Link>

                                </li>
                                <li>
                                <img src={twitter} alt="" />

                                </li>
                                <li>
                                    <Link to={`https://www.linkedin.com/company/mytra-club/`} target="_blank" >
                                <img src={linkedIn} alt="" />
                                </Link>
                                </li>
                                <li> 
                                    <Link to={`https://www.instagram.com/mytra.club?igsh=NzcyOTFsMGlxdWVw`} target="_blank">
                                       <img src={instagram} alt="" />
                                       </Link>
                                </li>
                            
                            </ul>
                            
                         
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <div className="footer_bottom_row">  
                <div className="container-fluid">
                    <div className="copyright-content">
                        <p>© 2024 All Rights Reserved  |  INITX INNOVATIONS PVT. LTD.</p>
                    </div>
                </div>
            </div> */}
        </footer>
    )
}
export default VendorFooter;