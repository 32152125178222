import React, { useState } from "react";




import { useNavigate } from "react-router-dom";
import ModalForm from "../vendorform/form";

const VendorHome = () => {
    const navigate = useNavigate();
    const [open ,setOpen] = useState(false)
    return (
        <>
        <section className="vendor-banner" id="hero">
        <div className="vendor-container">
            <div className="vendor-banner-inner ">
                <h1>Onboard your Brand and start selling for free
on <span> MYTRA! </span></h1>
<p>We Earn When You Earn</p>
<button className="btn dark-btn px-5" onClick={() => setOpen(!open)} > Start for Free </button>
     {
        open && 
          <ModalForm openModal={open} setOpenModal={setOpen} />
     }
            
            </div>
        </div>
    </section>
        </>
    )
}

export default VendorHome;