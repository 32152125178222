import React from "react";
import community from "../../../assets/vendorLogo/about/communities.png";
import global from "../../../assets/vendorLogo/about/sellglobe.png";
import selling from "../../../assets/vendorLogo/about/selling.png";




import { useNavigate } from "react-router-dom";
import VendorAboutSecond from "./secondSection";

const VendorAbout = () => {
    const navigate = useNavigate();
    return (
        <>
        <section id="vendor-about" className="d-flex gap-5 flex-column" >
            <div className="vendor-container">
                <div className="row align-items-center justify-content-center justify-content-lg-start ">
                      <div className="col-lg-4 col-md-6 ">
                        <div className="vendor-about-card" >
                            <img src={community} alt="" />
                            <div className="vendor-about-card-text-cont" >
                                <h4 className="m-0" >Multiple Communities</h4>
                                <p className="m-0" >Reach diverse customer bases by selling within various interest-focused communities.</p>
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 mt-1 mt-md-0 ">
                        <div className="vendor-about-card" >
                            <img src={global} alt="" />
                            <div className="vendor-about-card-text-cont" >
                                <h4 className="m-0">Sell Across Country</h4>
                                <p className="m-0" > Expand your market by selling products nationwide, connecting with buyers from all over India.</p>
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 mt-2 mt-lg-0 ">
                        <div className="vendor-about-card" >
                            <img src={selling} alt="" />
                            <div className="vendor-about-card-text-cont" >
                                <h4 className="m-0" >Easy Selling Tools</h4>
                                <p className="m-0" >Streamline your sales with Mytra’s easy-to-use tools for listing, managing, and tracking.</p>
                            </div>
                        </div>
                      </div>

                    {/* <div className="col-md-6">
                        <img src={about_device} alt="" className="img-fluid" />
                    </div> */}
                </div>


            </div>
        <VendorAboutSecond />

        </section>
        </>
    )
}

export default VendorAbout;





