import React from "react";
import login_logo from '../../../assets/vendorLogo/actuallyWorks/login.png'
import cart from '../../../assets/vendorLogo/actuallyWorks/cart.png'
import shipping from '../../../assets/vendorLogo/actuallyWorks/Shiping.png'
import money from '../../../assets/vendorLogo/actuallyWorks/Moneyhand.png'
import arrow from '../../../assets/vendorLogo/actuallyWorks/arroeindicator.png'





import { useNavigate } from "react-router-dom";

const VendorWork = () => {
    const navigate = useNavigate();
    return (
        <>
        <section  id="work">
        <div className="vendor-container">
            <div className="actually-work-container" >
                <h1> How it Actually Works? </h1>
             
            </div>
            
       
        </div>

       <div className="step-parent-cont" >
        <div className="middle-path" ></div>
        <div className="step-card-cont vendor-container">
  
        <StepCard
          icon={login_logo}
          title="Seamless Signup"
          description="Register with us by sharing GST and Bank account."
        />
        <Arrow />
        <StepCard
        icon={cart}
          title="Easy Selling"
          description="Login and upload the community-focused product catalogue."
        />
        <Arrow />
        <StepCard
         icon={shipping}
          title="Swift Shipping"
          description="We use AI to select the fastest and cheapest courier partner for order shipping."
        />
        <Arrow />
        <StepCard
         icon={money}
          title="Speedy Payout"
          description="Receive money directly in your account within 7-days of order delivery."
        />
        </div>

      </div>



    </section>
        </>
    )
}
const StepCard = ({ icon, title, description }) => {
    return (
        <div className="work-step-card ">
          <div className="steps-card-body ">
            <div className="icon-container"> <img src={icon} alt="icon" />  </div>
            <h5 className="card-title ">{title}</h5>
            <p className="card-text ">{description}</p>
          </div>
        </div>
    );
  };
  
  const Arrow = () => {
    return (
        <div className=" arrow-indicator-cont" >
      <div className="indicator-cont  ">
        <div className="d-flex justify-content-center">
          <div className="arrow-icon"><img src={arrow} alt="" /></div>
        </div>
      </div>
      </div>
    );
  };
 
  
export default VendorWork;