import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import header_logo from "../../../assets/vendorLogo/footer/footerlogo.png";

import { RxCross1 } from "react-icons/rx";
import { height } from "@mui/system";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  // p: 4,
  height: "90%",
  overflow: "auto",
  pb: 2,
};

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobileNumber: Yup.string().required("Mobile number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  address: Yup.string().required("Address is required"),
  companyName: Yup.string().required("Company name is required"),
  description: Yup.string().required("Description is required"),
});

export default function ModalForm({ openModal, setOpenModal }) {
  const [open, setOpen] = React.useState(openModal);
  const handleClose = () => setOpen(false);
  const handleSubmitToGoogleForm =async (values) => {

    const response = await fetch('https://api.mytra.club/admin/vendor-inquiry', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name:values.name,
              mobile_no:values.mobileNumber,
              email: values.email,
              address:values.address,
              company_name:values.companyName,
              description:values.description
            }), 
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Success:', data);

        toast.success('Form submitted successfully!');
    const googleFormUrl =
      "https://docs.google.com/forms/d/e/1FAIpQLSfQy1kcA--AvMNhDVyzccIyygx-_hwMFt10Yh62Fl7HIO_3UQ/formResponse";
    const formData = new FormData();

    // Map Formik fields to Google Form entry IDs
    formData.append("entry.1116720348", values.name); // Replace with your actual Google Form entry ID for name
    formData.append("entry.1734911259", values.mobileNumber); // Replace with actual ID for mobile number
    formData.append("entry.76763592", values.email); // Replace with actual ID for email
    formData.append("entry.50393860", values.address); // Replace with actual ID for address
    formData.append("entry.1297135036", values.companyName); // Replace with actual ID for company name
    formData.append("entry.2094253688", values.description); // Replace with actual ID for description

    // Submit to Google Form
    fetch(googleFormUrl, {
      method: "POST",
      body: formData,
      mode: "no-cors", // Required for cross-origin requests
    })
      .then(() => {
        // alert('Form submitted successfully!');
        toast.success("Submit Successfully ");
        handleClose();
        setOpenModal(false);
      })
      .catch(() => {
        // alert('Form submission failed.');
      });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="model-header">
            <div className="d-flex align-items-center ">
              <img src={header_logo} alt="" className="img-fluid" />

              <h1 className="vendor_header_heading text-light m-0 ">Vendor</h1>
            </div>
            <RxCross1
              className="text-light"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose();
                setOpenModal(false);
              }}
            />
          </div>

          <div className="model-body">
            <p id="modal-modal-title" variant="h6" component="h2">
              contact Us
            </p>
            <Formik
              initialValues={{
                name: "",
                mobileNumber: "",
                email: "",
                address: "",
                companyName: "",
                description: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmitToGoogleForm(values)}
            >
              {({ handleChange, handleBlur, values }) => (
                <Form>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    margin="normal"
                    className="model-field"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    style={{ color: "red" }}
                  />

                  <TextField
                    fullWidth
                    label="Mobile Number"
                    name="mobileNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobileNumber}
                    margin="normal"
                    className="model-field"
                  />
                  <ErrorMessage
                    name="mobileNumber"
                    component="div"
                    style={{ color: "red" }}
                  />

                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    margin="normal"
                    className="model-field"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    style={{ color: "red" }}
                  />

                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                    margin="normal"
                    className="model-field"
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    style={{ color: "red" }}
                  />

                  <TextField
                    fullWidth
                    label="Company Name"
                    name="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    margin="normal"
                    className="model-field"
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    style={{ color: "red" }}
                  />

                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    margin="normal"
                    multiline
                    rows={3}
                    className="model-field"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    style={{ color: "red" }}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
