import React from "react";
import header_logo from "../../../assets/vendorLogo/logo.svg";
import { Link, useNavigate } from "react-router-dom";


const VendorPageHeader = () => {
    const navigate = useNavigate();
    
    return (
        <>
        <header id="Vendor-header">
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="vendor-container">
                    <div className="d-flex align-items-end " >
                    <a className="navbar-brand" href="#">
                        <img src={header_logo} alt="" className="img-fluid" />
                       
                    </a>
                    <h1 className="vendor_header_heading" >Vendor</h1>
                    </div>
{/* 
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}

                    <div className="  justify-content-end" id="collapsibleNavbar">
                        <ul className="navbar-nav text-center align-items-lg-center">
                            

                            <li className="nav-item">
                                <Link to='/' className="dark-btn btn m-0 ">Home</Link>
                            </li>


                        </ul>
                    </div>
                </div>
            </nav>
        </header>
        </>
    )
}

export default VendorPageHeader;