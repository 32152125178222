import React, { useState } from "react";
import mobiles from "../../../assets/vendorLogo/about/mobiles.png";
import { useNavigate } from "react-router-dom";
import ModalForm from "../vendorform/form";

const VendorAboutSecond = () => {
    const navigate = useNavigate();
    const [open ,setOpen] = useState(false)

    return (
        <>
        <section id="vendor-aboutSecond" className="p-0 pt-3" >
            <div className="vendor-container">
                
                        <div className="about-free-card" >
                            <div  className="about-free-card-text  " >
                              <img src={mobiles} alt="" />
                              <p>At <span> MYTRA</span>, you don’t need to pay <br />
any upfront fees! Just onboard and sell!</p>
                            </div>
                            <button className=" pink-btn "  onClick={() => setOpen(!open)} > Start for Free </button>
                            {
        open && 
          <ModalForm openModal={open} setOpenModal={setOpen} />
     }
                        </div>

            </div>

        </section>
        </>
    )
}

export default VendorAboutSecond;





