import React from "react";
import './vendorePage.css';

import VendorPageHeader from "./Header/header";
import VendorHome from "./Home/home";
import VendorAbout from "./About/about";
import VendorBetter from "./better/better";
import VendorWork from "./ActualyWorks/work";
import VendorJoinCLub from "./joinClub/join";
import VendorFooter from "./Footer/footer";



const VendorPage = () => {
    return (
        <div>
        <VendorPageHeader />
        <VendorHome />
        <VendorAbout />
        <VendorBetter />
        <VendorWork />
        <VendorJoinCLub />
        <VendorFooter />
        </div>
    )
}

export default VendorPage;