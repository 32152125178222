import React from "react";
import hassle from "../../../assets/vendorLogo/Better/Hassle free.png";
import noPaidAds from "../../../assets/vendorLogo/Better/No paid ads.png";
import zero from "../../../assets/vendorLogo/Better/Zero onboarding.png";

import { useNavigate } from "react-router-dom";

const VendorBetter = () => {
    const navigate = useNavigate();
    return (
        <>
        <section id="vendor-about" className="p-0 pt-3" >
            <div className="vendor-container">
                
                       <div className="row align-items-center  ">
                        <div className="col-lg-6 "> 
                        <div className="better-card-left" >
                            <h1> Why MYTRA is better?</h1>
                             <p>At MYTRA, vendor’s success is our success, here are some salient features which will help you strengthen your business.</p> 
                             </div>
                              </div>
                        <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end ">
                            <div className="better-card-right" >
                                <div className="better-card" >
                                    <img src={zero} alt="" />
                                    <div className="better-card-text-cont">
                                        <h4> Be a partner, earn together </h4>
                                        <p>Listing your product on 
MYTRA is completely free.
There are no hidden charges.</p>
                                    </div>
                                </div>
                                <div className="better-card better-card-border" >
                                    <img src={noPaidAds} alt="" />
                                    <div className="better-card-text-cont">
                                        <h4> Don’t spend your fortune </h4>
                                        <p>Don’t spend on paid ads, be on MYTRA and get niche audience for your product.</p>
                                    </div>
                                </div>
                                <div className="better-card" >
                                    <img src={hassle} alt="" />
                                    <div className="better-card-text-cont">
                                        <h4> A hassle free experience </h4>
                                        <p>Delivering your products is super easy with MYTRA.
With all types of support 24/7.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                       </div>

            </div>

        </section>
        </>
    )
}

export default VendorBetter;





